.root {
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding-bottom: 40px;
  text-align: center;

  &_title {
    font-size: 20px;
    color: #2c3a5d;
    font-weight: 600;
    margin-left: auto;
    margin-bottom: 10px;
    margin-right: auto;
    margin-top: 0;
    line-height: 1.4;

    html[lang='ar'] & {
      font-size: 22px;
    }
  }
  &_desc {
    font-size: 16px;
    color: #1f3255;
    opacity: 0.8;
    line-height: 1.6;
  }
  &_actions {
    margin-top: 26px;

    :global .bp4-button {
      min-height: 36px;

      & + .bp4-button {
        margin-left: 10px;
      }
    }
  }
}
