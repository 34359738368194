@import '../_base.scss';

.bp4-drawer {
  .bp4-drawer-header {
    margin-bottom: 2px;
    background-color: #FFF;

    .bp4-heading {
      font-weight: 500;
    }

    .bp4-heading,
    .bp4-icon {
      color: #354152;
    }
  }
}

.drawer{
  &__insider{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    background-color: #fbfbfb;
  }

  &__main-tabs{
    .bp4-tabs {
      .bp4-tab-list {
        position: relative;
        background-color: #fff;
  
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: #e1e2e8;
        }
  
        > *:not(:last-child) {
          margin-right: 25px;
        }
  
        &.bp4-large > .bp4-tab {
          font-size: 15px;
          color: #555;
          margin: 0 0.8rem;
  
          &[aria-selected='true'],
          &:not([aria-disabled='true']):hover {
            color: $pt-link-color;
          }
        }
      }
  
      .bp4-tab-panel {
        margin-top: 0;
  
        .card {
          margin: 15px;
        }
      }
    }  
  }
}