
.menu{
  :global .bp4-heading{
    font-weight: 400;
    opacity: 0.5;
    font-size: 12px;
  }
}

.button{
  min-width: 34px;
}