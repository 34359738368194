.hint {
  margin-left: 6px;
  position: relative;
  top: -1px;

  .bp4-icon {
    color: #a1b2c5;
  }

  .bp4-popover-target:hover .bp4-icon {
    color: #90a1b5;
  }
  .bp4-icon {
    vertical-align: middle;
  }
}
