@use "sass:math";
@import './_base.scss';

$section-min-height: $pt-grid-size * 5 !default;
$section-padding-vertical: $pt-grid-size !default;
$section-padding-horizontal: $pt-grid-size * 2 !default;
$section-card-padding: $pt-grid-size * 2 !default;

$section-min-height-compact: $pt-grid-size * 4 !default;
$section-padding-compact-vertical: 7px !default;
$section-padding-compact-horizontal: 15px !default;
$section-card-padding-compact: $pt-grid-size * 1.5 !default;

.#{$ns}-section {
  overflow: hidden;
  width: 100%;

  &,
  &.#{$ns}-compact {
    // override Card compact styles here
    padding: 0;
  }

  &-header {
    align-items: center;
    border-bottom: 1px solid $pt-divider-black;
    display: flex;
    gap: $pt-grid-size * 2;
    justify-content: space-between;
    min-height: $section-min-height;
    padding: 0 $section-padding-horizontal;
    position: relative;
    width: 100%;

    &.#{$ns}-dark,
    .#{$ns}-dark & {
      border-color: $pt-dark-divider-white;
    }

    &-left {
      align-items: center;
      display: flex;
      gap: $pt-grid-size;
      padding: $section-padding-vertical 0;
    }

    &-title {
      margin-bottom: 0;
    }

    &-sub-title {
      margin-top: 2px;
    }

    &-right {
      align-items: center;
      display: flex;
      gap: $pt-grid-size;
      margin-left: auto;
    }

    &-divider {
      align-self: stretch;
      margin: $pt-grid-size * 1.5 0;
    }

    &.#{$ns}-interactive {
      cursor: pointer;

      &:hover,
      &:active {
        background: $light-gray5;

        &.#{$ns}-dark,
        .#{$ns}-dark & {
          background: $dark-gray4;
        }
      }
    }
  }

  &-card {
    &.#{$ns}-padded {
      padding: $section-card-padding;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $pt-divider-black;

      &.#{$ns}-dark,
      .#{$ns}-dark & {
        border-color: $pt-dark-divider-white;
      }
    }
  }

  &.#{$ns}-section-collapsed {
    .#{$ns}-section-header {
      border: none;
    }
  }

  &.#{$ns}-compact {
    .#{$ns}-section-header {
      min-height: $section-min-height-compact;
      padding: 0 $section-padding-compact-horizontal;

      &-left {
        padding: $section-padding-compact-vertical 0;
      }
    }

    .#{$ns}-section-card.#{$ns}-padded {
      padding: $section-card-padding-compact;
    }
  }
}